import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from '../components/HeroOther'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import AboveFooter from "../components/AboveFooter"

export const AboutPageTemplate = ({ seo, hero, sectionlist, cardsection, fixedimagesection }) => {
  return (
    <div>
      <SEO title={seo.title} description={seo.description} />
      <HeroOther data={hero} />
      <div>
        {
          sectionlist.list.map((item, i) => {
            if ((i % 2) === 0) {
              return (
                <div key={i} className="section" style={{ background: `${item.sectionbg}` }}>
                  <div className="container">
                    <div className="columns">
                      <div className="column is-8">
                        <h1
                          className="has-text-weight-bold is-size-4-mobile is-size-3-tablet is-size-2-widescreen"
                          style={{ color: `${item.textcolor}`, lineHeight: "1", marginTop: "1rem" }}
                        >
                          {item.title}<br />
                          {item.secondtitle}
                        </h1>
                        <p
                          style={{ color: `${item.textcolor}`, margin: "2rem 0" }}
                        >
                          {item.description}

                        </p>
                        <a href={item.buttonlink} >
                          <button style={{ marginBottom: "2rem", background: `${item.buttonbg}` }}>
                            {item.buttontxt}
                          </button>
                        </a>
                      </div>
                      <div className="column is-4">
                        <PreviewCompatibleImage imageInfo={item.image} />
                      </div>
                    </div>

                  </div>
                </div>
              )
            } else {
              return (
                <div key={i} className="section" style={{ background: `${item.sectionbg}` }}>
                  <div className="container">
                    <div className="columns">
                      <div className="column is-4">
                        <PreviewCompatibleImage imageInfo={item.image} />
                      </div>
                      <div className="column is-8">
                        <h1
                          className="has-text-weight-bold is-size-4-mobile is-size-3-tablet is-size-2-widescreen"
                          style={{ color: `${item.textcolor}`, lineHeight: "1", marginTop: "1rem" }}
                        >
                          {item.title}<br />
                          {item.secondtitle}
                        </h1>
                        <p style={{ color: `${item.textcolor}`, margin: "2rem 0" }}>
                          {item.description}
                        </p>
                        <a href={item.buttonlink} >
                          <button style={{ marginBottom: "2rem", background: `${item.buttonbg}` }}>
                            {item.buttontxt}
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          })
        }
      </div>
      <div className="section" style={{ background: "#eee", paddingTop: "5em" }}>
        <div className="container">
          <h1 className="has-text-weight-bold" style={{ padding: "3em 0", textAlign: "center", width: "100%" }}>{cardsection.title}</h1>
          <div className="columns" >
            {
              cardsection.list.map((card, i) => {
                return (
                  <div key={i} className="column is-3" style={{ padding: "0 1rem" }}>
                    <PreviewCompatibleImage imageInfo={card.image} />
                    <div style={{ background: "white", padding: "1rem", textAlign: "center" }}>
                      <h1 className="has-text-weight-bold">{card.name}</h1>
                      <h1 style={{ fontStyle: "Italic" }}>{card.role} </h1>
                      <p style={{ color: "#53c5d1", margin: "1rem 0", fontSize: "14px" }}>
                        {card.description}
                      </p>
                      <a href={card.buttonlink}>
                        <button style={{ background: "#53c5d1" }}>Learn More</button>
                      </a>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <AboveFooter data={fixedimagesection} height="500px" />
    </div>
  )
}

AboutPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  sectionlist: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.array
  }),
  cardsection: PropTypes.shape({
    title: PropTypes.string,
    list: PropTypes.array
  }),
  fixedimagesection: PropTypes.object,
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        sectionlist={frontmatter.sectionlist}
        cardsection={frontmatter.cardsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        sectionlist {
          title
          list {
            title
            secondtitle
            sectionbg
            textcolor
            description
            buttontxt
            buttonbg
            buttonlink
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        cardsection {
          title
          list {
            image {
              childImageSharp {
                fluid(maxWidth: 260, maxHeight: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            role
            description
            buttonlink
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

